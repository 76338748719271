import revive_payload_client_9wDh5YTIBY from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_a2dqfe3dyz7osebaixv3iw3xri/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_niNHE3US0o from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_a2dqfe3dyz7osebaixv3iw3xri/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CBpSAeewkg from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_a2dqfe3dyz7osebaixv3iw3xri/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_UumrETx0Cj from "/app/node_modules/.pnpm/nuxt-site-config@3.1.1_vue@3.4.38_typescript@5.4.5_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_SPnsEinwDW from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_a2dqfe3dyz7osebaixv3iw3xri/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_nn5dFKXlE7 from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_a2dqfe3dyz7osebaixv3iw3xri/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_alZFzmkeEz from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_a2dqfe3dyz7osebaixv3iw3xri/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UVVMp0WeeS from "/app/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.13.0_encoding@0.1.13_eslint@8.57.0_ioredis@5_a2dqfe3dyz7osebaixv3iw3xri/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import error_handler_client_sQhJOBiqXf from "/app/plugins/error-handler.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_9wDh5YTIBY,
  unhead_niNHE3US0o,
  router_CBpSAeewkg,
  _0_siteConfig_UumrETx0Cj,
  payload_client_SPnsEinwDW,
  check_outdated_build_client_nn5dFKXlE7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_alZFzmkeEz,
  chunk_reload_client_UVVMp0WeeS,
  error_handler_client_sQhJOBiqXf,
  sentry_client_shVUlIjFLk
]